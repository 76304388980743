.react-select-search__control {
  width: 100%;
  min-height: 3rem !important;
  background-color: theme('colors.gray.g1') !important;
  border: 2px solid theme('colors.gray.g1') !important;
  box-shadow: none !important;
  justify-content: flex-start !important;
}

.react-select-search__value-container {
  justify-content: flex-start !important;
}

.react-select-search__control--is-focused {
  border-color: theme('colors.blue.b1') !important;
}

.react-select-search__value-container {
  padding: 0 12px !important;
}

.react-select-search__value-container > div {
  width: auto !important;
}

.react-select-search__input:focus {
  box-shadow: none !important;
}

.react-select-search__option,
.react-select-search__option--is-focused {
  text-align: start;
}

.react-select-search__multi-value {
  min-width: 30px !important;
  border-radius: 4px !important;
  background-color: theme('colors.blue.bl') !important;
}

.react-select-search__multi-value__label {
  font-size: 16px !important;
  line-height: 24px !important;
  color: theme('colors.blue.b1') !important;
}

.react-select-search__multi-value__remove {
  color: theme('colors.blue.b1') !important;
}

.react-select-search__multi-value__remove:hover {
  background-color: unset !important;
}

.input-range__label--max,
.input-range__label--min,
.input-range__label--value {
  color: theme('colors.black.DEFAULT');
  font-size: 14px;
  line-height: 24px;
  font-family: PoppinsRegular;
}

.input-range__label--max,
.input-range__label--min {
  top: -1.85rem;
}

.input-range__label--value > .input-range__label-container {
  background-color: theme('colors.white');
  /* padding: 0 0.5rem; */
}

#advances-search .input-range__label--value > .input-range__label-container {
  background-color: theme('colors.gray.g1');
  /* padding: 0 0.5rem; */
}

.input-range__label--value {
  top: -2.5rem;
}

.input-range__track--background {
  background: theme('colors.gray.g2');
}

.input-range__track--active {
  background: theme('colors.black.b1');
}

.input-range__slider-container {
  transition: none;
  z-index: 100;
}

.input-range__slider {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 4px;
  border: none;
  background-image: url('../../assets/icons/Slider.svg');
  margin-top: -0.9rem;
  margin-left: -0.725rem;
  transition: none;
}

.input-range__slider:active {
  transform: scale(1);
}

.input-range__track {
  border-radius: 0.4rem;
  height: 5px;
  transition: none;
  border-radius: 0;
}

.input-range__label--min,
.input-range__label--max {
  display: none;
}

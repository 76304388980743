@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    /* --green-light: 216 248 227; #D8F8E3; */
    /* --green-g1: 35 198 100; #23C664; */
    /* --blue-b1: 67 25 238; #4319EE; */
    /* --blue-light: 236 232 253; #ECE8FD; */
    /* --pink-light: 254 235 243; #FEEBF3; */
    /* --pink-p1: 240 57 132; #F03984; */
    /* --yellow-light: 255 238 196; #FFEEC4; */
    /* --yellow-y1: 255 200 57; #FFC839; */
    /* --red-r1: 244 46 51; #F42E33; */
    /* --gray-g1: 247 247 247; #f7f7f7; */
    /* --gray-g2: 217 217 217; #d9d9d9 */
    /* --gray-g3: 77 77 80; #4D4D50; */
    /* --gray-gm: 159 158 158; #9F9E9E; */
    /* --violet-v1: 124 60 216; #7C3CD8; */
    /* --white: 255 255 255; #ffffff; */
    /* --black-b1: 30 30 30; #1e1e1e; */

    --shadow-s1: 0px 10px 14px rgba(0, 0, 0, 0.09);
  }
}

@layer base {
  @font-face {
    font-family: PoppinsMedium;
    src: url('assets/fonts/Poppins-Medium.woff') format('woff');
  }

  @font-face {
    font-family: PoppinsRegular;
    src: url('assets/fonts/Poppins-Regular.woff') format('woff');
  }
}

@layer base {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

@layer utilities {
  .disabled {
    background-color: theme('colors.gray.g1');
    color: theme('colors.gray.g2');
    border-color: theme('colors.gray.g1');
    pointer-events: none;
  }
}

* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: PoppinsRegular;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.carousel__slide-focus-ring {
  outline: none;
}

/* TODO add tailwind-scrollbar */

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background: theme('colors.gray.g2');
  border-radius: 30px;
}

::-webkit-scrollbar-thumb:hover {
  background: theme('colors.gray.g2');
}

.loader {
  height: 4px;
  width: 130px;
  --c: no-repeat linear-gradient(#6100ee 0 0);
  background: var(--c), var(--c), #d7b8fc;
  background-size: 60% 100%;
  animation: l16 3s infinite;
}
@keyframes l16 {
  0% {
    background-position:
      -150% 0,
      -150% 0;
  }
  66% {
    background-position:
      250% 0,
      -150% 0;
  }
  100% {
    background-position:
      250% 0,
      250% 0;
  }
}

.react-tel-input .flag-dropdown {
  border: 1px solid theme('colors.gray.g2');
  background-color: theme('colors.white');
}

.react-tel-input .selected-flag {
  padding: 0 0.75rem;
  min-width: 4rem;
  width: auto;
}

.react-tel-input .flag {
  /* width: 1.5rem; */
}

.react-tel-input .arrow,
.react-tel-input .arrow.up {
  width: 1.5rem !important;
  height: 1.5rem !important;
  border-left: none !important;
  border-right: none !important;
  border-top: none !important;
  border-bottom: none !important;
  left: 1.5rem !important;
  /* right: 0rem; */
  top: -0.3rem !important;
  background-image: url('../../assets/icons/ArrowDownSmall.svg');
}

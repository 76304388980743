.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker {
  /* 350px */
  max-height: 21.875rem;
  overflow: hidden;
  display: flex;
  border-color: theme('colors.gray.g1');
  box-shadow: theme('boxShadow.s2');
  border-radius: 8px;
  padding: 1rem;
}

.react-datepicker__header {
  background-color: theme('colors.white');
  border-width: 0;
}

.react-datepicker__current-month,
.react-datepicker-time__header {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
}

.react-datepicker__day-names {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0.675rem 0.75rem 0.675rem;
}

.react-datepicker__day-name {
  text-transform: uppercase;
  color: theme('colors.gray.gm');
  font-size: 10px;
  line-height: 12px;
  margin: 0;
}

.react-datepicker__time-container {
  border-width: 0;
}

.react-datepicker__week {
  display: table;
  border-spacing: 4px;
}

.react-datepicker__day {
  width: 30px;
  height: 30px;
  margin: 0;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  display: table-cell;
  vertical-align: middle;
  color: theme('colors.black.b1');
}

.react-datepicker__day:hover {
  border-radius: 100%;
  background-color: theme('colors.gray.g1');
}

.react-datepicker__day--keyboard-selected {
  border-radius: 100%;
  background-color: theme('colors.white');
}

.react-datepicker__day--selected,
.react-datepicker__day--selected:hover {
  background-color: theme('colors.blue.b1');
  border-radius: 100%;
  color: theme('colors.white');
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker__navigation--previous {
  left: 6px;
}

.react-datepicker__navigation--next {
  right: 6px;
}

/* TIME */

.react-datepicker__time-container,
.react-datepicker__time-box {
  width: unset !important;
}

.react-datepicker__time-list {
  height: 14rem !important;
}

.react-datepicker__time-list > :not([hidden]) ~ :not([hidden]) {
  margin: 4px;
}

.react-datepicker__time-list-item {
  height: 30px !important;
  padding: 3px 10px !important;
  font-size: 16px;
  color: theme('colors.black.b1');
  /* display: table-cell;
  vertical-align: middle; */
}

.react-datepicker__time-list-item:hover {
  background-color: theme('colors.gray.g1') !important;
  border-radius: 4px;
}

.react-datepicker__time-list-item--selected,
.react-datepicker__time-list-item--selected:hover {
  background-color: theme('colors.blue.b1') !important;
  font-weight: 400 !important;
  border-radius: 4px;
}

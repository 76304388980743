.react-calendar {
  width: 300px !important;
  padding: 24px;
}

.react-calendar__navigation {
  margin-bottom: 0 !important;
}

.react-calendar__navigation__arrow {
  color: theme('colors.black.b1');
}

.react-calendar__navigation__label {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
}

.react-calendar__navigation__next-button > svg {
  margin-right: 0px !important;
}

.react-calendar__tile,
.react-calendar__month-view__days__day,
.react-calendar__month-view__days__day--weekend,
.react-calendar__month-view__days__day--neighboringMonth {
  padding: 0 !important;
  width: 36px;
  height: 36px;
  border-radius: 100%;
}

.react-calendar__tile--active {
  background-color: theme('colors.blue.b1') !important;
}

.react-calendar__tile--now {
  /* background-color: unset !important; */
  /* background-color: '#ffff76' !important; */
}

.react-calendar__month-view__weekdays__weekday > abbr:where([title]),
.react-calendar__month-view__weekdays__weekday--weekend > abbr:where([title]) {
  text-decoration: none !important;
  color: theme('colors.gray.gm');
  font-size: 10px;
  line-height: 12px;
}

.rs-settings__control {
  width: 100%;
  min-height: 42px !important;
  background-color: theme('colors.white') !important;
  border: 1px solid theme('colors.gray.g2') !important;
  box-shadow: none !important;
  justify-content: flex-start !important;
}

.rs-settings__value-container {
  justify-content: flex-start !important;
}

.rs-settings__control--is-focused {
  border-color: theme('colors.blue.b1') !important;
}

.rs-settings__value-container {
  padding: 0 12px !important;
}

.rs-settings__value-container > div {
  width: auto !important;
}

.rs-settings__input:focus {
  box-shadow: none !important;
}

.rs-settings__option,
.rs-settings__option--is-focused {
  text-align: start;
}

.rs-settings__single-value {
  font-size: 12px;
  line-height: 24px;
  color: theme('colors.black.b1');
}

/* React Calendar */

.react-calendar__tile--range {
  background-color: theme('colors.blue.bl') !important;
  color: theme('colors.black.b1') !important;
  border-radius: 0;
}

.react-calendar__tile--rangeStart {
  background-color: theme('colors.blue.b1') !important;
  color: theme('colors.white') !important;
  border-bottom-left-radius: 100% !important;
  border-top-left-radius: 100% !important;
  position: relative;
}

.react-calendar__tile--rangeStart::after {
  content: '';
  width: 18px;
  height: 36px;
  background-color: theme('colors.blue.bl');
  position: absolute;
  top: 0;
  right: 0;
  clip-path: path(evenodd, 'M18,18 Q16,2 0,0 L18,0 z Q16,35 0,37 L18,37 z');
}

.react-calendar__tile--rangeEnd {
  background-color: theme('colors.blue.b1') !important;
  color: theme('colors.white') !important;
  border-bottom-right-radius: 100% !important;
  border-top-right-radius: 100% !important;
  position: relative;
}

.react-calendar__tile--rangeEnd::after {
  content: '';
  width: 18px;
  height: 36px;
  background-color: theme('colors.blue.bl');
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  clip-path: path(evenodd, 'M0,18 Q1,2 18,0 L-1,0 L-1,37 L18,37 Q1,35 0,18 ');
}
